<template>
  <div class="organisation">
    <section class="boxAcceuil content-center container-lg box-center ">
    <div class="bluehead">
            <div class="row">
                <div style="text-align:center" class="col"><h5> Profil </h5></div>                       
            </div>
    </div>
    
    <center>
        <div class="card-grand-profil border">
            <div class="col row border">
                <div class="col">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>
                    </div>
                </div>
                <div class="col">
                    <span>Nom Prenom:</span>
                    <br><span>Poste:</span>
                    <br><span>Type de compte:</span>

                </div>
            </div>

            <div class="col">

            </div>
        </div>
    </center>
    
    
      
  </section>
  </div>
</template>
<script>
// @ is an alias to /src


export default {
  name: 'Profil',
  components: {
    
  }
}
</script>